import "./About.css";
import Footer from '../modules/Footer.js';
import {useEffect, useRef} from 'react';
import {ReactComponent as GitIcon} from '../images/github.svg';
import {ReactComponent as LinkIcon} from '../images/link-solid.svg';

export default function ProjectLinks(props) {
    
    const refMenueOpen = useRef(null);

    //let content disappear for full site menu
    useEffect(()=>{
        // console.log(`menue open? ${props.menueForSmallInput}`)
        if(window.innerWidth < 1280 && props.menueForSmallInput === true){
            refMenueOpen.current.style.display = 'none';
         } else {
            refMenueOpen.current.style.display = 'block';
        }
     }, [props.menueForSmallInput]);

    return(
        <div className="Layout">
            <div className="Main-Content Content" ref={refMenueOpen}>
                <h1 className="Template-Headline" style={props.stylesContentGerman}>Projekte</h1>
                <h1 className="Template-Headline" style={props.stylesContentEnglish}>Projects</h1>
                <div className="Grid-About">
                    
                <div style={props.stylesContentGerman}>
                        {/*  Invoice App */}
                        <p>
                            Invoice App Frontend Mentor Guru Herausforderung<br/>
                            Aktuelles Projekt<br/>
                            &#8594; Next.js 13 Beta, TypeScript, MongoDB
                        </p>
                        <a target="blank" href="https://invoice-app-2-jfjd4zzyl-quaerksi.vercel.app/">
                            <LinkIcon className="LinkExtern"/>
                        </a>
                        <a target="blank" href="https://github.com/Quaerksi/invoice-app">
                            <GitIcon className="LinkExtern LinkExtern-MarginLeft"/>
                        </a>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        {/*  Invoice App */}
                        <p>
                            Invoice App Frontend Mentor Guru Challenge <br/>
                            Current project.<br/>
                            &#8594; Next.js 13 Beta, TypeScript, MongoDB
                        </p>
                        <a target="blank" href="https://invoice-app-2-jfjd4zzyl-quaerksi.vercel.app/">
                            <LinkIcon className="LinkExtern"/>
                        </a>
                        <a target="blank" href="https://github.com/Quaerksi/invoice-app">
                            <GitIcon className="LinkExtern LinkExtern-MarginLeft"/>
                        </a>
                    </div>

                    <div style={props.stylesContentGerman}>
                        {/*  Hire me */}
                        <p>
                            Hire me - Eine Animation <br/>
                            Eigenes Programmierprojekt im Oktober 2022. Länge: 1 Min. 15 Sek.<br/>
                            &#8594; JavaScript, CSS
                        </p>
                        <a target="blank" href="https://quaerksi.github.io/hire-me/">
                            <LinkIcon className="LinkExtern"/>
                        </a>
                        <a target="blank" href="https://github.com/Quaerksi/hire-me">
                            <GitIcon className="LinkExtern LinkExtern-MarginLeft"/>
                        </a>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        {/*  Hire me */}
                        <p>
                            Hire me - An animation <br/>
                            Own programming project in October 2022. Length: 1 min 15 sec.<br/>
                            &#8594; JavaScript, CSS
                        </p>

                        <a target="blank" href="https://quaerksi.github.io/hire-me/">
                            <LinkIcon className="LinkExtern"/>
                        </a>
                        <a target="blank" href="https://github.com/Quaerksi/hire-me">
                            <GitIcon className="LinkExtern LinkExtern-MarginLeft"/>
                        </a>
                    </div>

                    <div style={props.stylesContentGerman}>
                        {/*  Weather App german*/}
                        <p>
                            Ein Wetter Widget <br/>
                            Entsanden im Rahmen einer Coding Challenge in einem Bewerbungsverfahren im August 2022.<br/>
                            &#8594; next.js, Zustand.js, React SWR Hook
                        </p>
                        {/* <a className="Project-Link" target="blank" href="https://weather-widget-jade.vercel.app/">&#128279;</a> */}
                        <a target="blank" href="https://weather-widget-jade.vercel.app/">
                            <LinkIcon className="LinkExtern"/>
                        </a>
                        <a target="blank" href="https://github.com/Quaerksi/weather-widget">
                            <GitIcon className="LinkExtern LinkExtern-MarginLeft"/>
                        </a>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        {/* Weather App english*/}
                        <p>
                            A weather widget <br/>
                            Programmed as part of a coding challenge in an application process in August 2022.<br/>
                            &#8594; next.js, Zustand.js, React SWR Hook
                        </p>

                        <a target="blank" href="https://weather-widget-jade.vercel.app/">
                            <LinkIcon className="LinkExtern"/>
                        </a>
                        <a target="blank" href="https://github.com/Quaerksi/weather-widget">
                            <GitIcon className="LinkExtern LinkExtern-MarginLeft"/>
                        </a>
                    </div>

                    <div style={props.stylesContentGerman}>
                        {/*  landingpage german*/}
                        <p>
                            Bookmark Landingpage Frontend Mentor Herausforderung <br/>
                            Eigenes Programmierprojekt im Juli 2022.<br/>
                            &#8594; JavaScript, HTML, CSS
                        </p>
                        <a target="blank" href="http://bookmark-landing-page.salevsky.net/">
                            <LinkIcon className="LinkExtern"/>
                        </a>
                        <a target="blank" href="https://github.com/Quaerksi/bookmark-landing-page">
                            <GitIcon className="LinkExtern LinkExtern-MarginLeft"/>
                        </a>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        {/*  landingpage english*/}
                        <p>
                            Bookmark Landingpage Frontend Mentor Challenge <br/>
                            Own programming project in June 2022. <br/>
                            &#8594; JavaScript, HTML, CSS
                        </p>
                        
                        <a target="blank" href="http://bookmark-landing-page.salevsky.net/">
                            <LinkIcon className="LinkExtern"/>
                        </a>
                        <a target="blank" href="https://github.com/Quaerksi/bookmark-landing-page">
                            <GitIcon className="LinkExtern LinkExtern-MarginLeft"/>
                        </a>
                    </div>

                        <div style={props.stylesContentGerman}>
                        {/* Chat App german*/}
                        <p>
                            Eine kleine Chatanwendung <br/>
                            Eigenes Programmierprojekt im Juni 2022.<br/>
                            &#8594; JavaScript, HTML, CSS, express, EJS, socket.io, dotenv, bcryptjs, cookie-parser, jsonwebtoken, mongoose
                        </p>

                        {/* <a target="blank" href="https://juliettes-great-chat-app.herokuapp.com">
                            <LinkIcon className="LinkExtern"/>
                        </a> */}
                        <a target="blank" href="https://github.com/Quaerksi/chatapp-withLoginAndDesign">
                            <GitIcon className="LinkExtern"/>
                        </a>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        {/* Chat App english*/}
                        <p>
                            A small chat application <br/>
                            Own programming project in June 2022.<br/>
                            &#8594; JavaScript, HTML, CSS, express, EJS, socket.io, dotenv, bcryptjs, cookie-parser, jsonwebtoken, mongoose
                        </p>
{/*                         
                        <a target="blank" href="https://juliettes-great-chat-app.herokuapp.com">
                            <LinkIcon className="LinkExtern"/>
                        </a> */}
                        <a target="blank" href="https://github.com/Quaerksi/chatapp-withLoginAndDesign">
                            <GitIcon className="LinkExtern"/>
                        </a>
                    </div>

                    <div style={props.stylesContentGerman}>
                        {/*  my blog german*/}
                        <p>
                            Meine Webseite - Juliettes Blog <br/>
                            Eigenes Programmierprojekt im Februar 2022.<br/>
                            &#8594; React, JavaScript, HTML, CSS, PHP
                        </p>
                        <a target="blank" href="https://www.salevsky.net/">
                            <LinkIcon className="LinkExtern"/>
                        </a>
                        <a target="blank" href="https://github.com/Quaerksi/mywebsite">
                            <GitIcon className="LinkExtern LinkExtern-MarginLeft"/>
                        </a>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        {/*  my blog english*/}
                        <p>
                            My website - Juliettes Blog <br/>
                            Own programming project in Februar 2022.<br/>
                            &#8594; React, JavaScript, HTML, CSS, PHP
                        </p>
                        
                        <a target="blank" href="https://www.salevsky.net/">
                            <LinkIcon className="LinkExtern"/>
                        </a>
                        <a target="blank" href="https://github.com/Quaerksi/mywebsite">
                            <GitIcon className="LinkExtern LinkExtern-MarginLeft"/>
                        </a>
                    </div>

                    <div style={props.stylesContentGerman}>
                        {/*  tic tac toe german*/}
                        <p>
                            Tic Tac Toe Spiel<br/>
                            Abgabeaufgabe zur Evaluation im Rahmen der Weiterbildung in modernen Webanwendungen mit Java Script im Herbst 2020.<br/>
                            &#8594; express, socket.io
                        </p>

                        {/* <a target="blank" href="https://juliettes-tic-tac-toe.herokuapp.com/">
                            <LinkIcon className="LinkExtern"/>
                        </a> */}
                        <a target="blank" href="https://github.com/Quaerksi/tic-tac-toe">
                            <GitIcon className="LinkExtern"/>
                        </a>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        {/*  tic tac toe english*/}
                        <p>
                            Tic Tac Toe game <br/>
                            Submission task for evaluation as part of further training in modern web applications with Java Script in autumn 2020.<br/>
                            &#8594; express, socket.io
                        </p>
                        
                        {/* <a target="blank" href="https://juliettes-tic-tac-toe.herokuapp.com/">
                            <LinkIcon className="LinkExtern"/>
                        </a> */}
                        <a target="blank" href="https://github.com/Quaerksi/tic-tac-toe">
                            <GitIcon className="LinkExtern"/>
                        </a>
                    </div>

                    <div style={props.stylesContentGerman}>
                        {/*  Snake Game german*/}
                        <p>
                            Ein kleines Schlangenspiel <br/>
                            &#8594; vanilla JS
                        </p>

                        <a target="blank" href="https://quaerksi.github.io/snake-game/">
                            <LinkIcon className="LinkExtern"/>
                        </a>
                        <a target="blank" href="https://github.com/Quaerksi/mywebsite/tree/main/snakegame">
                            <GitIcon className="LinkExtern LinkExtern-MarginLeft"/>
                        </a>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        {/* Snake Game english*/}
                        <p>
                            A small snake game <br/>
                            &#8594; vanilla JS
                        </p>
                        <a target="blank" href="https://quaerksi.github.io/snake-game/">
                            <LinkIcon className="LinkExtern"/>
                        </a>
                        <a target="blank" href="https://github.com/Quaerksi/mywebsite/tree/main/snakegame">
                            <GitIcon className="LinkExtern LinkExtern-MarginLeft"/>
                        </a>
                    </div>
                </div>
            </div>
            <Footer stylesContentGerman={props.stylesContentGerman} stylesContentEnglish={props.stylesContentEnglish}/>
        </div>
    )
}