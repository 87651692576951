import './About.css';
import Footer from '../modules/Footer.js';
import {useEffect, useRef} from 'react';
import {ReactComponent as GitIcon} from '../images/github.svg';
import {ReactComponent as LinkIcon} from '../images/link-solid.svg';

export default function About(props){

    // menue
    const refMenueOpen = useRef(null);

    //let content disappear for full site menu
    useEffect(()=>{
        // console.log(`menue open? ${props.menueForSmallInput}`)
        if(window.innerWidth < 1280 && props.menueForSmallInput === true){
            refMenueOpen.current.style.display = 'none';
         } else {
            refMenueOpen.current.style.display = 'block';
        }
     }, [props.menueForSmallInput]);

     // Function will execute on click of button
    const onButtonClick = () => {
        // using Java Script method to get PDF file
        fetch('pdf/MarTech-collectionOfTerms.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'MarTech-collectionOfTerms.pdf';
                alink.click();
             })
        })
    }

    return(
        // About-Wrapper 
        <div className="Layout">
            <div className="Main-Content Content" ref={refMenueOpen}>
                <h1 className="Template-Headline" style={props.stylesContentGerman}>Mein Blog</h1>
                <h1 className="Template-Headline" style={props.stylesContentEnglish}>My blog</h1>
                <div className="Grid-About">

                    {/* <div style={props.stylesContentGerman}>
                        <h3 className="H3-About"></h3>
                        <p className="P-About">
                             
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About"></h3>
                        <p className="P-About">
                            
                        </p>
                    </div> */}

                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">19.12.22 - Ich bin da</h3>
                        <p className="P-About">
                           Auf dem Weg zu meinem Erfolg.
                           <br/><br/>
                           <a target="blank" href="https://github.com/Quaerksi/invoice-app">
                                <GitIcon className="LinkExtern"/>
                            </a>
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">12/19/22 - Still there</h3>
                            On the way to my success
                           <br/><br/>
                           <a target="blank" href="https://github.com/Quaerksi/invoice-app">
                                <GitIcon className="LinkExtern"/>
                            </a>
                    </div>
                 
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">11.12.22 - JS Info des Tages</h3>
                        <p className="P-About">
                            <h4>JS damals und heute</h4>
                            Pfeilfunktionen haben kein eigenes this. Sie haben auch keine arguments Objekt - Aber zum Glück den ...Rest-Operator.
                            Obwohl arguments sowohl Array-artig als auch iterierbar ist, ist es kein Array. Array-Methoden werden nicht unterstützt.
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">12/11/22 - JS info of the day</h3>
                        <p className="P-About">
                            <h4>JS then and now</h4>
                            Arrow functions don't have their own this. They don't have the special 
                            arguments object either - But lukily the Rest Operator.
                            Although arguments is both array-like and iterable, it's not an array. It does not support array methods.
                        </p>
                    </div>
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">08.12.22 - Ich tauche ein in datengetriebenes Marketing</h3>
                        <p className="P-About">
                        Eine Sammlung von Begriffen, Abkürzungen, Erklärungen für datengetriebenes Marketing
                            <button onClick={onButtonClick} className="btn btnAbout">Sammlung herunterladen</button>
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">12/08/22 - I dive into data-driven marketing</h3>
                        <p className="P-About">
                            A collection of terms, abbreviations, explanations for data-driven marketing
                            <button onClick={onButtonClick} className="btn btnAbout" >Download technical terms</button>
                        </p>
                    </div> 
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">06.12.22 - 3 Stunden - Invoice App - Eigenes Dropdownmenu</h3>
                        <p className="P-About">
                            <ul>
                                <li>natürlich .tsx konform</li>
                                <li>jedes Detail im Figma File beachtet</li>
                                <li>aus unordered list</li>
                                <li>als funktionale Komponente ausgelagert</li>
                                <li>mit props konsumierbar gemacht</li>
                                <li>mit var aus dem [data-theme="light"] gestaltet</li>
                                <li>Commit on Dec 6, 2022</li>
                            </ul>
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">12/06/22 - 3 hours - Invoice App - Own dropdown menu</h3>
                        <p className="P-About">
                            <ul>
                                <li>of course .tsx compliant</li>
                                <li>paid attention to every detail in the figma file</li>
                                <li>from unordered list</li>
                                <li>Outsourced as a functional component</li>
                                <li>made consumable with props</li>
                                <li>styled with var from the [data-theme="light"]</li>
                                <li>Commit on Dec 6, 2022</li>
                            </ul>
                        </p>
                    </div>
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">05.12.22 Die letzte Minute</h3>
                        <p className="P-About">
                            <p><q><cite>Gäbe es die letzte Minute nicht, so würde niemals etwas fertig.</cite></q> - Mark Twain</p>
                            Tatsächlich neige ich dazu, im Selbststudium ganz bewusst Umwege zu gehen.
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">12/05/22 The last minute</h3>
                        <p className="P-About">
                            <p><q><cite>If it wasn't for the last minute, nothing would ever get done</cite></q> - Mark Twain</p>
                            In fact, in my self-study, I quite consciously tend to take detours.
                        </p>
                    </div> 
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">03.12.22 - Invoice App - Next.js 13 beta</h3>
                        <p className="P-About">
                             Ich führe das Projekt jetzt im app Ordner fort und passe es an Next.js 13 beta an.
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">12/03/22 - Invoice App - Next.js 13 beta</h3>
                        <p className="P-About">
                            I'm now continuing the project in the app folder and adapting it to Next.js 13 beta.
                        </p>
                    </div>
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">02.12.22 - Invoice App - Statusmeldung</h3>
                        <p className="P-About">
                             <p><q><cite>Gegen Zielsetzungen ist nichts einzuwenden, sofern man sich dadurch nicht von interessanten 
                                 Umwegen abhalten lässt.</cite></q> - Mark Twain</p>
                            <h4>Das Ziel: </h4>
                            <ul>
                                <li>Erstellen der Designelemente aus dem Figma File</li>
                                <li>Font Optimizing mit google Font als Variablen Font mit @next/font/google einbinden</li>
                                <ul>
                                    <li>Font Spartan gibt es nicht mehr. Ich entscheide mich für League_Spartan</li>
                                </ul>
                                <li>Elemente zum Teil in Light und Dark Mode, daher</li>
                                <ul>
                                    <li>Kleines Layout mit HTML aside Tag erstellen, um dort Light/Dark Toggle anzubringen</li>
                                    <li>[data-theme=“dark“] erstellen</li>
                                    <li>Bei der Farbangabe entscheide ich mich für das HSR Farbsystem</li>
                                </ul>
                            </ul>
                            <h4>Die Umwege: </h4>
                            <ul>
                                <li>Variable Fonts</li>
                                <ul>
                                    <li>Ich erstelle den benötigten Font als Variablen Font. Da dieser aber nur wenige Optionen hergibt, 
                                suche ich mir den Font Roboto_Flex aus und spiele mit ihm.</li>
                                </ul>
                                <li>Custom Document.tsx</li>
                                <ul>
                                    <li>Ich überlege kurz, wo ich nochmal die Fonts am besten einbinde. Dabei begegne ich document.tsx.</li>
                                    <li>Ich entscheide mich für _app.tsx, setze aber trozdem _document.tsx für mein Projekt auf. Vielleicht finde ich ja auch noch Zeit für User Tracking.</li>
                                </ul>
                                <li>next.js 13 beta</li>
                                <ul>
                                    <li>Ich arbeite mit dem neuen app Ordner.</li>
                                    <li>Außerdem mit den neuen Features page.js, layout.js, head.js, template.js, loading.js, error.js</li>
                                </ul>
                            </ul>
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">12/02/22 - Invoice App - Status Message</h3>
                        <p className="P-About">
                        <p><q><cite>There's nothing wrong with setting goals as long as they don't distract you from interesting Detours.</cite></q> - Mark Twain</p>
                               <h4>The goal: </h4>
                               <ul>
                                   <li>Creating the design elements from the figma file</li>
                                   <li>Integrate font optimization with Google Font as variable font with @next/font/google</li>
                                   <ul>
                                       <li>The Spartan font no longer exists. I choose League_Spartan</li>
                                   </ul>
                                   <li>Parts of elements in light and dark mode, hence</li>
                                   <ul>
                                       <li>Create a first small layout with an HTML aside tag to place a light/dark toggle</li>
                                       <li>Create [data-theme="dark"]</li>
                                       <li>When specifying the color, I choose the HSR color system</li>
                                   </ul>
                               </ul>
                               <h4>The detours: </h4>
                               <ul>
                                   <li>Variable fonts</li>
                                   <ul>
                                       <li>I create the required font as a variable font. However, since this offers only a few possibilities,
                                   I choose the Roboto_Flex font and play around with it.</li>
                                   </ul>
                                   <li>Custom Document.tsx</li>
                                   <ul>
                                       <li>I'm thinking about where I can best integrate the fonts. I come across document.tsx. </li>
                                       <li>I decide for _app.tsx but also created a document.tsx for my project. Maybe I can still find time for user tracking.</li>
                                   </ul>
                                   <li>next.js 13 beta</li>
                                   <ul>
                                       <li>I'm working with the new app folder.</li>
                                       <li>Also with the new features page.js, layout.js, head.js, template.js, loading.js, error.js</li>
                                   </ul>
                               </ul>
                        </p>
                    </div>
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">22.11.22 - 2 Stunden - Invoice App - Backend stabil</h3>
                        <p className="P-About">
                             Im Backend funktionieren die Abfrage aller Rechnungen und die Abfrage einer Rechnung mittels ID.
                             Ich habe mit Postman durchgetestet und fange Fehler ab.<br/>
                             Als nächstes möchte ich die Design-System-Elemente programmieren und danach die Landingpage meiner 
                             Invoice App. So kann ich auch die Backendfunktion, welche alle Rechnungen wieder gibt, im Frontend Einsatz zu testen. <br/>
                             Ich habe Lust auf einen visuellen Erfolg.
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">11/22/22 - 2h Invoice App - Backend stable</h3>
                        <p className="P-About">
                            All invoices can be queried in the backend and an invoice can be queried using an ID.
                            I've tested with Postman and am catching errors.<br/>
                            Next I want to program the design system elements and then the landing page of my
                            ivoice app. So I can test the backend function that returns all invoices in the frontend.<br/>
                            I'm in the mood for a visual success.
                        </p>
                    </div>
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">21.11.22 Noch eine volle Woche</h3>
                        <p className="P-About">
                             Wie die letzte Woche ist auch diese sehr voll. Es wird nur wenig Zeit für mich und meinen Computer geben.
                             Ab nächster Woche ist es wieder gut.<br/>
                             Ich beginne heute Morgen mit einer kurzen Überarbeitung des Homepagetextes meiner Seite.
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">11/21/22 Another full week</h3>
                        <p className="P-About">
                            Like last week, this week is very busy. There will be little time for me and my computer.
                            From next week it's going again.<br/>
                            I'm starting this morning with a quick overhaul of my site's home page text.
                        </p>
                    </div> 
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">19.11.22- 1 Stunde - Invoice App - lokale Mongo DB eingerichtet</h3>
                        <p className="P-About">
                             Ich habe mir eine lokale MongoDB Instanz eingerichtet. Zum arbeiten habe ich mich für die MongoShell und gegen die UI von MongoCompass entschieden.<br/>
                             Im Code arbeite ich mit MongoClient vom MongoDB Node.js Driver.
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">11/19/22 - 1h - Invoice App -  Set up local Mongo DB</h3>
                        <p className="P-About">
                            I set up a local MongoDB instance. I chose to work with the MongoShell rather than the MongoCompass UI.<br/>
                            In code I'm working with MongoClient from MongoDB Node.js driver.
                        </p>
                    </div>
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">17.11.22 - 3 Stunden - Invoice App - Typescript, Next.js und das Promise-Objekt</h3>
                        <p className="P-About">
                            Ich will die DB-Abfragen von den Frontend-Komponenten meiner Next.js Anwendung trennen. Dh. in der getServersideprops-Funktion 
                            die DB-Bibliothek-Funktion aus der lib-Datei aufrufen. Die Datenbankabfrage ist für mich eine synchrone Funktion, welche im try-and-catch das gewünschte Ergebnis im 
                            return liefert. TypeScript will eine Funktion mit try-and-catch einen Rückgabetyp, welcher ein PromiseObjekt mit Typangabe ist. An dieses PromiseObjekt komme 
                            ich per then-Abfrage. Nun ist das Ganze asynchron. Das obligatorische return {"{props:{}}"} in der then-Anweisung registriert die 
                            Funktion getServersideProps aber nicht und eine return Anweisung am Ende würde immer zuerst ausgeführt.<br/>

                            Nach einigem hin und her übergebe ich das Promise-Objekt aus meiner DB-Bibliothek jetzt an meine API. Ein res.send ist aus dem then-Block 
                            heraus möglich ohne das TypeScript durch eine Fehlermeldung blockiert. In der Frontend Komponente benutze ich jetzt den guten alten SWR-Hook, um die Daten abzufragen. 
                            Nun wird alles angezeigt und 10 min später habe ich auch die letzten TypeScript Fehler verschwinden lassen.<br/>
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">11/17/22 - 3 hours - Invoice App -  Typescript, Next.js and the Promise object</h3>
                        <p className="P-About">
                        I want to separate the DB queries from the frontend components of my Next.js application. Ie. call the DB-library-function in the getServersideprops 
                        function. For me, the database call is a synchronous function that delivers the desired result in the return of an try...catch. TypeScript wants a return type, which 
                        is a type of PromiseObject. I get to this PromiseObject by querying it with .then(). Now the whole thing is asynchronous. However, the 
                        mandatory return {"{props:{}}"} in the then-statement of getServersideProps() does not register the function  for TypeScript <br/>

                        After some back and forth, I'm now passing the Promise object from my DB library to my API. A res.send is possible from within the then-block without 
                        TypeScript is blocking with an error. In the frontend component I now use the good old SWR hook to query the data. Now everything is displayed and 
                        10 minutes later I have also made the last TypeScript error disappear. <br/>
                        </p>
                    </div>
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">16.11.22 - Morgens - Invoice App -  MongoDB Database Tools</h3>
                        <p className="P-About">
                             Upload von invoice json Daten to MongoDB Atlas via MongoDB Database Tools<br/>
                             MongoDB Tools sind in GO programmiert. Diese Sprache möchte ich mir nach diesem Projekt einmal ansehen.
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">11/16/22 - Morning - Invoice App -  MongoDB Database Tools</h3>
                        <p className="P-About">
                            Upload invoice JSON Data to MongoDB Atlas via MongoDB Database Tools<br/>
                            MongoDB tools are programmed in GO. I would like to look at this language after this project.
                        </p>
                    </div>
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">15.11.22 Invoice App - Start im Backend</h3>
                        <p className="P-About">
                            Ich habe mich entschieden, zuerst das Backend zu programmieren. Eine GET Abfrage des gesamten 
                            Datenpakets ist nun möglich, wie auch eine Einzelne Rechnung mittels Id abgerufen werden kann. Momentan noch aus der Datei. <br/>
                            Ich achte darauf, die Funktionen zur Daten-abfrage und -änderung von der RESTful API zu 
                            trennen. Auch habe ich einen eigenen Ordner für Interfaces angelegt. Vielleicht programmiere ich die API später noch mit CORS. Noch ist die Arbeit mit TypeScript etwas fummelig.
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">11/15/22 Invoice App - Backend first</h3>
                        <p className="P-About">
                            I decided to program the backend first. A GET query of the whole
                            Data package is now possible, and an individual invoice can also be called up via an ID. currently still from the file <br/>
                            I made sure to seperate use the RESTful API's and data query modification capabilities
                            I also created a separate folder for interfaces. Maybe later I'll program the API with CORS. Working with TypeScript is still a bit fiddly.
                        </p>
                    </div>
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">12.11.2022 Frontend Mentor Herausforderung: Invoice App</h3>
                        <p className="P-About">
                             Ich beginne ein neues Projekt:
                             <span> <a className="CustomLink" target="blank" href="https://www.frontendmentor.io/challenges/invoice-app-i7KaLTQjl">Die Herausforderung</a></span>
                             <h4>Mein Aktionsplan</h4>
                             <ol>
                                 <li>Alle Dateien sichten.</li>
                                 <li>Werkzeuge festlegen:
                                     <ol>
                                         <li>Next.js</li>
                                         <li>TypeScript</li>
                                         <li>Zustand.js</li>
                                         <li>Modulares CSS mit Variablen</li>
                                     </ol>
                                 </li>
                                 <li>Die Projektumgebung einrichten.</li>
                                 <li>Projekt als öffentliches Repository initialisieren.</li>
                                 <li>Das Designsystems programmieren.</li>
                                 <li>Zuerst die HTML-Struktur erstellen, gefolgt vom Styling mit mobile first.
                                     <ol>
                                         <li>Hauptseite Rechnung</li>
                                         <li>Rechnung anzeigen</li>
                                         <li>Rechnungsformular zum Bearbeiten, Aktualisieren und Erstellen</li>
                                     </ol>
                                 </li>
                            </ol>
                             
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">11/12/22 Frontend Mentor Challenge: Invoice App</h3>
                        <p className="P-About">
                            I'm starting a new project:
                            <span> <a className="CustomLink" target="blank" href="https://www.frontendmentor.io/challenges/invoice-app-i7KaLTQjl">The Challenge</a></span>
                            <h4>My plan of action</h4>
                            <ol>
                                 <li>View the given files.</li>
                                 <li>Decision for the tools:
                                     <ol>
                                         <li>Next.js</li>
                                         <li>TypeScript</li>
                                         <li>Zustand.js</li>
                                         <li>Modular CSS with variables</li>
                                     </ol>
                                 </li>
                                 <li>Setting up the environment.</li>
                                 <li>Initialize as public repository.</li>
                                 <li>Building up the design system.</li>
                                 <li>Build HTML structure followed by styling in mobile first.
                                     <ol>
                                         <li>Main Page Invoice</li>
                                         <li>View an invoice</li>
                                         <li>Invoice Form for edit, update and create</li>
                                     </ol>
                                 </li>
                            </ol>
                        </p>
                    </div>
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">10.11.2022</h3>
                        <p className="P-About">
                             Heute leiste ich mir eine Frontend Mentor Pro Account für ein ganzes Jahr &#128513;.
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">11/10/22 Frontend Mentor Pro Account</h3>
                        <p className="P-About">
                            Today I'll afford a Frontend Mentor Pro Account for a whole year &#128513;.
                        </p>
                    </div>
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">18.10.2022 - Hire me - In CSS einen Turm aus Buchstaben bauen</h3>
                        <p className="P-About">
                            Für eine gute Reaktionsfähigkeit habe ich die Schriftgröße in viewport-width und den Textschatten und die Transformation in Prozent angegeben. Ich habe die 
                            Transformation in einer Animation deklariert und dort translateX() und translateY() verwendet. Keine Medienabfragen erforderlich. Ich hatte einen responsieven Buchstabenturm.
                            Ich habe das Ergebnis online gestellt und mit anderen Geräten überprüft. Wie sich herausstellte, drifteten die Buchstaben auf meinem Smartphone auseinander 
                            und auf meinem Tablet drängten sich die Buchstaben zusammen.<br/>
                            Zuerst musste ich mich mit dem unterschiedlichen Verhalten auseinandersetzen. Ich habe es behoben, indem ich die Schriftgrößendeklaration sowie die
                            Textschatten- und die Transformationsdeklaration in rem geändert habe. So gleiten die Buchstaben auf Smartphones und Tablets zusammen.<br/>
                            Jetzt wurde es fummelig. Auf dem Computer habe ich es so aussehen lassen, als würden die Buchstaben etwas nach hinten übereinander geschoben und auf Tablet 
                            und Smartphone sieht es so aus, als würden die Buchstaben übereinander nach vorne geschoben. Für kleinere Ansichtsfenster habe ich es mobilfreundlicher gemacht, 
                            für größere Ansichtsfenster habe ich es Desktop-freundlicher gestaltet. Ich verwende jetzt Medienabfragen für drei verschiedene Viewport-Größen, habe also 
                            insgesamt 4 verschiedene Größen. Und zu guter Letzt habe ich den Buchstaben eine Umrandung gegeben.
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">10/18/22 - Hire me - Build a tower with letters in CSS</h3>
                        <p className="P-About">
                            For a good responsiveness I specified the font size in viewport-width and the text shadow and transformation in percent. 
                            I declared the transformation in an animation and used translateX() and translateY() there. No media inquiries needed. I had a responsive tower of letters. 
                            I put the result online and checked with other devices. <br/> As it turned out the letters drifted apart on my smartphone and on my tablet the letters crowded together. 
                            So I had to deal with the different behavior first. I fixed it by changing the font size declaration, as well as the text shadow - and the transform 
                            declaration to rem. This is how the letters slide together on smartphones and tablets. <br/>
                            Now it got fiddly. On the computer I made it look as if the letters are pushed a bit backwards on top of each other and on tablet and smartphone it 
                            looks as if the letters are being pushed forward on top of each other. For smaller viewports, I've made it more mobile-friendly, for larger viewports, 
                            I've made it more desktop-friendly. I now use media queries for three different viewport sizes, so I have a total of 4 different sizes. And last but 
                            not least, I gave the letters an outline.
                        </p>
                    </div> 
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">14.10.2022 Remote-Debugging von Android-Geräten über Chrome</h3>
                        <p className="P-About">
                             Habe heut meine kleine JavaScript CSS Animmation remote debugged. Via Chrome ist das sehr komfortable. Der Fehler war im nu behoben.
                             Schon bald geht die Animation online.
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">10/14/22 Remote debug Android devices via Chrome</h3>
                        <p className="P-About">
                            I remotely debugged my little JavaScript CSS animation today. This is easily done via Chrome. The error was corrected within a very short time.
                            The animation will be online soon.
                        </p>
                    </div> 
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">15.09.2022 Zurück im Code</h3>
                        <p className="P-About">
                        Die Ferien sind zu Ende. Zum Start habe ich mich ein wenig durch LinkedIn gelesen und mein Profil 
                        auf den neuesten Stand gebracht. Danach strukturierte ich diese Webseite etwas um und änderte den 
                        Text der Startseite. Zudem habe ich die Links verschönert. Die Bearbeitung von SVGs in React ist wirklich sehr komfortabel.
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">09/15/22 Back in code</h3>
                        <p className="P-About">
                        The holidays are over. At the beginning I read through LinkedIn and updated my profile. After that I restructured this 
                        website a bit and changed the text of the homepage. I also improved the links. Editing SVGs in React is very convenient.
                        </p>
                    </div>

                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">15.08.2022 Ein Wetter Widget</h3>
                        <p className="P-About">
                            Eine next.js Anwendung mit Zustand.js und React SWR Hook.
                            <h4>Quellen</h4>
                            Wetterdaten:  
                            <span> <a className="CustomLink" target="blank" href="https://www.visualcrossing.com">Visualcrossing</a></span><br/>
                            Icons: 
                            <span> <a className="CustomLink" target="blank" href="https://fontawesome.com/">Font Awesome</a></span> &
                            <span> <a className="CustomLink" target="blank" href="https://www.flaticon.com/">Flaticon</a></span><br/>
                            <br/>
                            Ergebnis: 

                            {/*  SVGInject ? for hover */}
                            <a target="blank" href="https://weather-widget-jade.vercel.app/">
                                <LinkIcon className="LinkExtern LinkExtern-MarginLeft"/>
                            </a>
                            <a target="blank" href="https://github.com/Quaerksi/weather-widget">
                                <GitIcon className="LinkExtern LinkExtern-MarginLeft"/>
                            </a>
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">08/15/22 A weather Widget</h3>
                        <p className="P-About">
                            A next.js application with Zustand.js and React SWR Hook.
                            <h4>Sources</h4>
                            weather data:  
                            <span> <a  className="CustomLink" target="blank" href="https://www.visualcrossing.com">visualcrossing</a></span><br/>
                            icons: 
                            <span> <a  className="CustomLink" target="blank" href="https://fontawesome.com/">Font Awesome</a></span> &
                            <span> <a  className="CustomLink" target="blank" href="https://www.flaticon.com/">Flaticon</a></span><br/>
                            <br/>
                            result:
                            <a target="blank" href="https://weather-widget-jade.vercel.app/">
                                <LinkIcon className="LinkExtern LinkExtern-MarginLeft"/>
                            </a>
                            <a target="blank" href="https://github.com/Quaerksi/weather-widget">
                                <GitIcon className="LinkExtern LinkExtern-MarginLeft"/>
                            </a>
                        </p>
                    </div>
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">19.07.2022 Frontend Mentor Challenge Bookmark Landingpage</h3>
                        <p className="P-About">
                            Ergebnis: 
                            <a target="blank" href="http://bookmark-landing-page.salevsky.net/">
                                <LinkIcon className="LinkExtern LinkExtern-MarginLeft"/>
                            </a>
                            <a target="blank" href="https://github.com/Quaerksi/bookmark-landing-page">
                                <GitIcon className="LinkExtern LinkExtern-MarginLeft"/>
                            </a>
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">07/19/22 Frontend Mentor Challenge Bookmark Landingpage</h3>
                        <p className="P-About">
                            Result:
                            <a target="blank" href="http://bookmark-landing-page.salevsky.net/">
                                <LinkIcon className="LinkExtern LinkExtern-MarginLeft"/>
                            </a>
                            <a target="blank" href="https://github.com/Quaerksi/bookmark-landing-page">
                                <GitIcon className="LinkExtern LinkExtern-MarginLeft"/>
                            </a>
                        </p>
                    </div>
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">13.07.2022 Frontend Mentor Challenge Bookmark Landingpage</h3>
                        <p className="P-About">
                            Elemente programmiert. <br/><br/>

                            <span> <a className="CustomLink" target="blank" href="https://www.frontendmentor.io/challenges/bookmark-landing-page-5d0b588a9edda32581d29158">Die Herausforderung</a></span>
                            <br/><br/>
                            <a target="blank" href="https://github.com/Quaerksi/bookmark-landing-page-design-system">
                                <GitIcon className="LinkExtern"/>
                            </a>
                       </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">07/13/22 Frontend Mentor Challenge Bookmark Landingpage</h3>
                        <p className="P-About">
                             Programmed elements. <br/><br/>

                            <span> <a className="CustomLink" target="blank" href="https://www.frontendmentor.io/challenges/bookmark-landing-page-5d0b588a9edda32581d29158">The Challenge</a></span>
                            <br/><br/>
                            <a target="blank" href="https://github.com/Quaerksi/bookmark-landing-page-design-system">
                                <GitIcon className="LinkExtern"/>
                            </a>
                        </p>
                    </div>
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">11.07.22 Best-Practice-Training beendet</h3>
                        <p className="P-About">
                            CSS, HTML Best-Practice-Training mit Kevin Powell - Build a space travel website 
                            <br/><br/>
                            <span> <a className="CustomLink" target="blank" href="https://scrimba.com/learn/spacetravel">Learn modern CSS</a></span>
                            <br/><br/>
                            <span><a className="CustomLink" target="blank" href="https://www.frontendmentor.io/challenges/space-tourism-multipage-website-gRWj1URZ3">Die Herausforderung</a></span><br/>
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">07/11/22 Finished CSS best practice training</h3>
                        <p className="P-About">
                            CSS, HTML best practice Training with Kevin Powell - Build a space travel website 
                            <br/><br/>
                            <span> <a className="CustomLink" target="blank" href="https://scrimba.com/learn/spacetravel">Learn modern CSS</a></span>
                            <br/><br/>
                            <span><a className="CustomLink" target="blank" href="https://www.frontendmentor.io/challenges/space-tourism-multipage-website-gRWj1URZ3">The Challenge</a></span><br/>
                        </p>
                    </div>                    
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">22.06.22 LinkedIn</h3>
                        <p className="P-About">
                             Ich erstelle ein LinkedIn Profil.
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">6/22/22 LinkedIn</h3>
                        <p className="P-About">
                            I create a LinkedIn profile.
                        </p>
                    </div>
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">21.06.22 Chat App Part 3: Login und Design</h3>
                        <p className="P-About">
                            Meine ChatApp hat jetzt einen Login.
                            Bekannte Bugs: Anzeige wer online ist aktualisiert sich nicht automatisch.
                            <br/><br/>
                            <a target="blank" href="https://juliettes-great-chat-app.herokuapp.com">
                                <LinkIcon className="LinkExtern"/>
                        </a>
                        <a target="blank" href="https://github.com/Quaerksi/chatapp-withLoginAndDesign">
                            <GitIcon className="LinkExtern LinkExtern-MarginLeft"/>
                        </a>
                        </p>
                    </div>
                    
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">6/21/22 Chat App Part 3: Login and Design</h3>
                        <p className="P-About">
                            My chat app has a login now.
                            Known bugs: Display of who is online does not update automatically.
                            <br/><br/>
                            <a target="blank" href="https://juliettes-great-chat-app.herokuapp.com">
                                <LinkIcon className="LinkExtern"/>
                        </a>
                        <a target="blank" href="https://github.com/Quaerksi/chatapp-withLoginAndDesign">
                            <GitIcon className="LinkExtern LinkExtern-MarginLeft"/>
                        </a>
                        </p>
                    </div>
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">10.06.22 Steuer angefertigt</h3>
                        <p className="P-About">
                            Yippi
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">6/10/22 tax made</h3>
                        <p className="P-About">
                            Yippi
                        </p>
                    </div>
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">06.06.22 Chat App Part 2: MongoDB <span>Datenbankanbindung</span></h3>
                        <p className="P-About">
                             Chatnachrichten werden nun mit Hilfe von mongosh in der nicht relationalen Datenbank dauerhaft gespeichert. 
                             <br/><br/>
                            <a target="blank" href="https://github.com/Quaerksi/chat-app-with-db-connection">
                                <GitIcon className="LinkExtern"/>
                            </a>
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">6/6/22 Chat App Part 2: MongoDB database connection</h3>
                        <p className="P-About">
                            Chat messages are now stored in the non-relational database using Mongosh.
                            <br/><br/>
                            <a target="blank" href="https://github.com/Quaerksi/chat-app-with-db-connection">
                                <GitIcon />
                            </a>
                        </p>
                    </div>
                     
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">17.05.22 Juliettes Block: Upgrade</h3>
                        <p className="P-About">
                             u.a. Die Seite "Mein Projekt" ist jetzt "Mein Blog".<br/>
                             Scroll to Top Funktion für Routenänderung hinzugefügt. <br/>
                             Verbesserte Grammatik.
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">5/17/22 Juliettes Block: Redesign this page</h3>
                        <p className="P-About">
                            i.a The "My Project" page is now "My Blog".<br/>
                            Add scroll to top function on route change. <br/>
                            Improve grammar.
                        </p>
                    </div>
                    
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">15.05.22 Basis Mongo DB Anwendung programmiert</h3>
                        <p className="P-About">
                            Grundlagen erarbeitet <br/>
                            Im Modell-View-Controller Prinzip. Kann sehr gut als Paket ausgelagert werden.<br/>
                            Erste Erfahrung mit Postman.
                            <br/><br/>
                            <a target="blank" href="https://github.com/Quaerksi/mongoDB_module_starter">
                                <GitIcon className="LinkExtern"/>
                            </a>
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">05/15/22 Programmed a base Mongo DB application.</h3>
                        <p className="P-About">
                            Learned the basics.<br/>
                            In the model-view-controller principle. Can very well be outsourced as a package.<br/>
                            First experience with Postman.
                            <br/><br/>
                            <a target="blank" href="https://github.com/Quaerksi/mongoDB_module_starter">
                                <GitIcon className="LinkExtern"/>
                            </a>
                        </p>
                    </div>
                    
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">15.04.22 Chat App Part 1: Chat Anwendung</h3>
                        <p className="P-About">
                             Ich habe eine Chat Anwendung mit Objektdatenhaltung programmiert. Details zum Projekt sind auf der Seite zu finden. 
                            <br/><br/>
                            <a target="blank" href="https://github.com/Quaerksi/chat-app-object-data-management">
                                <GitIcon className="LinkExtern"/>
                            </a>
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">04/15/22 Chat App Part 1: Chat App</h3>
                        <p className="P-About">
                            I have programmed a chat application with object data management. Details about the project can be found on the page.
                            <br/><br/>
                            <a target="blank" href="https://github.com/Quaerksi/chat-app-object-data-management">
                                <GitIcon className="LinkExtern"/>
                            </a>
                        </p>
                    </div>
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">15.03.22 Juliettes Block: Beim Bildschirmgröße verringern klappt das Menü selbstständig aus</h3>
                        <p className="P-About">
                            Lösung: Listener für Bildschirmgröße eingefügt mit Debouncer.                       
                        </p>
                    </div>
                    
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">03/15/22 Juliettes Block: When reducing the screen size, the menu folds out automatically</h3>
                        <p className="P-About">
                            Solution: Insert listener for Screensize with debounce.                      
                        </p>
                    </div>
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">05.02.22 Juliettes Block: Formulardaten per E-Mail weiterleiten mit Axios</h3>
                        <p className="P-About">
                            Läuft
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">02/05/22 Juliettes Block: Forward form data via email with Axios</h3>
                        <p className="P-About">
                            Runs
                        </p>
                    </div>
                    <div style={props.stylesContentGerman}>
                            <h3 className="H3-About">28.01.22 Juliettes Block: Weiterleitung zum Schlangenspiel unsicher</h3>
                            <p className="P-About">
                                Mögliche Lösung: Mit Wildcard Zertifikat schützen.
                            </p>
                        </div>
                        <div style={props.stylesContentEnglish}>
                            <h3 className="H3-About">01/28/22 Juliettes Block: The redirection to the snake game is not secure</h3>
                            <p className="P-About">
                                Possible solution: Protect with a wildcard certificate.
                            </p>
                        </div>
                        
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">20.01.22 Juliettes Block: Client-Side-Routing mit React Router</h3>
                        <p className="P-About">
                            Beim Neuladen einer Seite erhalte ich folgende Fehlermeldung: "The requested URL was not 
                            found on this server." <br/>
                            Lösung: Forwarding mittels PHP <br/>
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">01/20/22 Juliettes Block: Client-Side-Routing with React Router</h3>
                        <p className="P-About">
                            When reloading a page I get the following error message: "The requested URL was not
                            found on this server." <br/> <br/>
                            Solution: Forwarding using PHP <br/>
                        </p>
                    </div>
                    <div style={props.stylesContentGerman}>
                        <h3 className="H3-About">15.01.21 Starte meinen Blog</h3>
                        <p className="P-About">
                            Diese React Website wird mit folgenden Technologien erstellt:
                            <ul>
                                <li>Java Script ES6</li>
                                <li>HTML5</li>
                                <li>CSS3</li>
                                <li>PHP</li>
                                <li>NPM</li>
                                <li>Git</li>
                                <li>Visual Studio Code</li>
                                <li>Gimp</li>
                                <li>Notepad</li>
                            </ul>
                            Sie läuft auf Strato Hosting Plus mit einem PHP Apache Unix Server.
                        </p>
                    </div>
                    <div style={props.stylesContentEnglish}>
                        <h3 className="H3-About">01/15/21 Start my blog</h3>
                        <p className="P-About">
                            This React website is built using the following technologies:
                            <ul>
                                <li>Java Script ES6</li>
                                <li>HTML5</li>
                                <li>CSS3</li>
                                <li>PHP</li>
                                <li>NPM</li>
                                <li>Git</li>
                                <li>Visual Studio Code</li>
                                <li>Gimp</li>
                                <li>Notepad</li>
                            </ul>
                            It runs on Strato Hosting Plus with a PHP Apache Unix Server.
                        </p>
                    </div>
                </div>
                
            </div>
            <Footer stylesContentGerman={props.stylesContentGerman} stylesContentEnglish={props.stylesContentEnglish}/> 
        </div>
        
    )
};