import {useEffect, useRef} from 'react';
import "../sites/About.css";

// image import
// import imgNewJob from '../images/newJob.jpg';

function HomepageContent(props) {

  const refMenueOpen = useRef(null);

  //let content disappear for full site menu
  useEffect(()=>{
   if(window.innerWidth < 1280 && props.menueForSmallInput === true){
       refMenueOpen.current.style.display = 'none';
    } else {
      refMenueOpen.current.style.display = 'block';
   }
}, [props.menueForSmallInput]);

  return (
    <div className="Main-Content Content" ref={refMenueOpen}>
      <div className="Grid-About Grid-About-Homepage">
      <section className="Content Content-Homepage NoBorder" style={props.stylesContentGerman}>
        <div className="Hompage-Introduction ">
          <div>
            <h1 className="Template-Headline-Homepage">Hallo, ihr Lieben.</h1>
           <p> Ich bin eine Webentwicklerin im Full Stack</p>

            <p>Mein Tech-Stack:</p>

            <ul>
              <li><b>JavaScript ES6 </b> mit Higher Order- and Call Back Functions, Arrow Functions, Default Values, Classes, Modules, 
                Rest Parameters, Spread Syntax, Destructering, Closures.</li>
              <li><b>TypeScript</b></li>
              <li><b>CSS</b> mit Variablen und modular.</li>
              <li><b>React</b>, <b>Next.js</b> mit <b>Next.js 13 Beta</b> </li>
              <li><b>Node.js</b> mit unter anderem express, EJS, socket.io, dotenv, bcryptjs, cookie-parser, jsonwebtoken, mongoose.</li>
              <li><b>MongoDB</b> mit Mongosh, Mongoose und MongoDB Node.js.</li>
            </ul>
            <p>Ich habe Erfahrung mit <b>PHP</b> und in <b>Java</b>.<br/>
            Ich bin auf der Suche nach einer herausfordernden Arbeit, der ich mich stellen darf. Ich bringe meine Vorhaben zu Ende und komme, um zu bleiben. Ich liebe das Gefühl, über mich selbst hinaus zu wachsen.<br/>
            An Teamarbeit mag ich, dass sie mich pusht und voran treibt.<br/>
            
            {/* Ich hoste auf Vercel, GithubPages und Strato. <br/> */}
            {/* Ich kann eine von Webmasters Europe e. V. zertifizierte Weiterbildung ,Moderne Webanwendungen mit JavaScript' vorweisen.<br/>
            Ich habe einen Bachelor of Science der Medieninformatik. 
            Im Studium wie auch in der Weiterbildung habe ich Abgaben stets zeitgerecht eingereicht. */}
            </p>
            <p>Hier mein <a href="https://github.com/Quaerksi/" target="blank">GitHub Account</a>. 
            Besucht gerne auch mein <a href="https://www.linkedin.com/in/link-juliette/" target="blank">LinkedIn</a> Profil.</p>  
            <p>Herzliche Grüße Juliette</p>
            </div>
            {/* <img className="newJob" src={'./images/me.jpg'}></img>   */}
        </div>
      </section>
      <section className="Content Content-Main NoBorder" style={props.stylesContentEnglish}>
      <div className="Hompage-Introduction ">
          <div>
            <h1 className="Template-Headline-Homepage">Hello</h1>
            <p> I'm a full stack web developer</p>

            <p>My Tech Stack:</p>

            <ul>
              <li><b>JavaScript ES6</b> with higher order- and call back functions, arrow functions, default values, classes, modules, 
                rest parameters, spread syntax, destructering and closures.</li>
              <li><b>TypeScript</b></li>
              <li><b>CSS</b> with variables and modular.</li>
              <li><b>React</b>, <b>Next.js</b> with <b>Next.js 13 Beta</b></li>
              <li><b>Node.js</b> with, among others: express, EJS, socket.io, dotenv, bcryptjs, cookie-parser, jsonwebtoken, mongoose.</li>
              <li><b>MongoDB</b> with Mongosh, Mongoose and MongoDB Node.js.</li>
            </ul>
            <p>I have experience with <b>PHP</b> and in <b>Java</b>.<br/>
              I'm looking for a challenging job that I can take on. I finish my projects and come to stay. I love the feeling of growing beyond myself.<br/>
              What I like about teamwork is that it drives me and pushes me forward. <br/>
            
            {/* I host on Vercel, GithubPages and Strato.<br/> */}
            {/* I have completed advanced training in 'Modern Web Applications with JavaScript', certified by Webmasters Europe e. V.<br/>
            I have a Bachelor of Science in Media Informatics. I always submitted on time, both during my studies and in further education. */}
            </p>
            <p>My GitHub Account <a href="https://github.com/Quaerksi/" target="blank">GitHub Account</a>.
            Feel free to visit my <a href="https://www.linkedin.com/in/link-juliette/" target="blank">LinkedIn</a> profile.</p>
            <p>Best regards Juliette</p>
            </div>
            {/* <img className="newJob" src={'./images/newJob.jpg'}></img>   */}
        </div>
      </section> 
     
      </div>
    </div>
  );
}

export default HomepageContent;